import { useMediaQuery } from 'react-responsive';
import { generateMedia } from 'styled-media-query';

export const breakpoints = {
  mobile: 600,
  tablet: 900,
  menuSwitch: 960,
  laptop: 1200,
  largeLaptop: 1400,
  desktop: 1800,
};

export function useBreakpoint(values) {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.mobile - 1 });
  const isTablet = useMediaQuery({ minWidth: breakpoints.mobile, maxWidth: breakpoints.tablet - 1 });
  const isDesktop = useMediaQuery({ minWidth: breakpoints.tablet });

  if (!Array.isArray(values)) {
    return '';
  }

  if (values.length === 1) {
    return values[0];
  }

  if (values.length === 2) {
    if (isMobile) {
      return values[0];
    }

    if (isTablet || isDesktop) {
      return values[1];
    }
  }

  if (values.length === 3) {
    if (isMobile) {
      return values[0];
    }

    if (isTablet) {
      return values[1];
    }

    if (isDesktop) {
      return values[2];
    }
  }
}

const media = generateMedia({
  mobile: '600px',
  tablet: '900px',
  menuSwitch: '960px',
  laptop: '1200px',
  largeLaptop: '1400px',
  desktop: '1800px',
});

export const mq = {
  ltsm: media.lessThan('mobile'),
  ltms: media.lessThan('menuSwitch'),
  btmlmd: media.between('tablet', 'largeLaptop'),
  gtsm: media.greaterThan('mobile'),
  gtmd: media.greaterThan('tablet'),
  gtms: media.greaterThan('menuSwitch'),
  gtml: media.greaterThan('largeLaptop'),
  gtlg: media.greaterThan('laptop'),
  gtxl: media.greaterThan('desktop'),
};
