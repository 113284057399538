import React from 'react';
import { Link } from 'gatsby-plugin-react-intl';
import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { mq, useBreakpoint } from '@utils/responsive';

export const fontStyles = {
  normal: css`
    font-family: ${props => fonts(props.theme.locale).SERIF};
    font-size: 18px;
    line-height: 160%;
    white-space: pre-line;

    & a {
      color: ${colors.B500};
      text-decoration: underline;
    }
  `,
  h1: css`
    font-family: ${props => props.theme.locale === 'zh_Hans' ?
                    fonts(props.theme.locale).SERIF_SC :
                    fonts(props.theme.locale).SANS_CONDENSED};
    font-size: ${props => props.theme.locale === 'zh_Hans' ? '36px' : '40px'};
    font-weight: ${weights.BOLD};
    line-height: ${props => props.theme.locale === 'zh_Hans' ? '100%' : '90%'};
    text-transform: ${(props) => (props.noCaps ? 'none' : 'uppercase')};

    ${props => props.theme.locale === 'zh_Hans' ?
        mq.gtsm`
          font-size: 58px;
        ` :
        mq.gtsm`
          font-size: 64px;
        `
    }

    ${props => props.theme.locale === 'zh_Hans' ?
        mq.gtlg`
          font-size: 74px;
        ` :
        mq.gtlg`
          font-size: 82px;
        `
    }
  `,
  h2: css`
    font-size: 64px;
    font-family: ${props => props.theme.locale === 'zh_Hans' ?
                    fonts(props.theme.locale).SERIF_SC :
                    fonts(props.theme.locale).SANS_CONDENSED};
    font-weight: ${weights.BOLD};
    line-height: 100%;

    ${mq.gtlg`
      font-size: 74px;
    `}
  `,
  h3: css`
    font-family: ${props => props.theme.locale === 'zh_Hans' ?
                    fonts(props.theme.locale).SERIF_SC :
                    fonts(props.theme.locale).SANS_CONDENSED};
    font-size: 32px;
    font-weight: ${weights.BOLD};
    line-height: 100%;
  `,
  h4: css`
    font-family: ${props => props.theme.locale === 'zh_Hans' ?
                    fonts(props.theme.locale).SERIF_SC :
                    fonts(props.theme.locale).SANS_CONDENSED};
    font-size: 28px;
    font-weight: ${weights.BOLD};
    line-height: 110%;
  `,
  h5: css`
    font-family: ${props => props.theme.locale === 'zh_Hans' ?
                    fonts(props.theme.locale).SERIF_SC :
                    fonts(props.theme.locale).SANS_CONDENSED};
    font-size: 20px;
    line-height: 120%;
  `,
  blockquote: css`
    font-family: ${props => fonts(props.theme.locale).SANS_CONDENSED};
    font-size: 64px;
    font-weight: ${weights.BOLD};
    line-height: 100%;
  `,
  em: css`
    font-style: italic;
  `,
  strong: css`
    font-weight: ${weights.BOLD};
  `,
};

const BodyComponent = styled.p`
  ${fontStyles.normal};

  ${(props) =>
    props.typeSize === 'small' &&
    css`
      font-size: 14px;
      line-height: 150%;
    `}

  ${(props) =>
    props.typeSize === 'large' &&
    css`
      font-size: 20px;
    `}
`;

export const Body = ({ size, sizes, ...rest }) => {
  const responsiveSize = useBreakpoint(sizes);
  const typeSize = size || responsiveSize || 'regular';

  return <BodyComponent typeSize={typeSize} {...rest} />;
};

const CaptionComponent = styled.p`
  font-family: ${props => fonts(props.theme.locale).SANS};
  line-height: 120%;

  & a {
    color: ${(props) => getThemeColor(props.colorTheme)};
    font-weight: ${weights.BOLD};
    text-decoration: underline;
  }

  ${(props) =>
    props.typeSize === 'small' &&
    css`
      font-size: 12px;
    `}

  ${(props) =>
    props.typeSize === 'regular' &&
    css`
      font-size: 16px;
    `}
`;

export const Caption = ({ size, sizes, colorTheme, ...rest }) => {
  const responsiveSize = useBreakpoint(sizes);
  const typeSize = size || responsiveSize || 'regular';

  return <CaptionComponent colorTheme={colorTheme} typeSize={typeSize} {...rest} />;
};

const ctaStyles = css`
  color: ${colors.N900};
  font-family: ${props => fonts(props.theme.locale).SANS_CONDENSED};
  font-size: 16px;
  line-height: 120%;
  font-weight: ${weights.BOLD};
  text-decoration: underline;

  ${mq.gtsm`
    font-size: 20px;
    line-height: 24px;
  `}
`;

const ExternalCTA = styled.a`
  ${ctaStyles};
`;

const InternalCTA = styled(Link)`
  ${ctaStyles};
`;

export const CTA = ({ external, ...rest }) => {
  if (external) {
    return <ExternalCTA {...rest} target="_blank" rel="noopener noreferrer" />;
  }

  return <InternalCTA {...rest} />;
};

const headingLevels = {
  100: styled.h1`
    ${fontStyles.h1};
  `,
  200: styled.h2`
    ${fontStyles.h2};

    ${(props) =>
      props.caps
        && css`
            font-size: 64px;
            line-height: 90%;
            text-transform: uppercase;
          `}
  `,
  300: styled.h3`
    ${fontStyles.h3};
  `,
  400: styled.h4`
    ${fontStyles.h4};
  `,
  500: styled.h5`
    ${fontStyles.h5};
  `,
};

export const Heading = ({ level, levels, ...rest }) => {
  const responsiveLevel = useBreakpoint(levels);
  const headingLevel = level || responsiveLevel;

  if (!headingLevel) {
    return null;
  }

  const Component = headingLevels[headingLevel];

  if (Component) {
    return <Component {...rest} />;
  }

  return null;
};

export const Quote = styled.p`
  ${fontStyles.blockquote};
`;

export function getThemeColor(colorTheme) {
  switch (colorTheme) {
    case 'blue':
      return colors.B500;
    case 'green':
      return colors.G500;
    case 'orange':
      return colors.O500;
    case 'purple':
      return colors.P500;
    default:
      return colors.B500;
  }
}
